import jwtDecode from "jwt-decode";
import { ENTRYPOINT } from "./config/entrypoint";

const authProvider = {
    login: ({ username, password }) =>  {
        const email = username;
        const request = new Request(`${ENTRYPOINT}/authentication_token`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem("token", token);
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkError: error => {
        if ([401, 403].includes(error?.status || error?.response?.status)) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: params => {
        try {
            // @ts-ignore
            if (!localStorage.getItem("token") || new Date().getTime() / 1000 > jwtDecode(localStorage.getItem("token"))?.exp) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: params => Promise.resolve(),
};

export default authProvider;