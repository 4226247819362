import {
    ListGuesser,
    ShowGuesser,
    FieldGuesser
  } from "@api-platform/admin";
import { List, Datagrid, TextField, EmailField, TopToolbar, ListButton, ShowButton, EditButton, Edit } from 'react-admin';

const TicketTitle = ({ record }) => {
    return <span>Report {record ? `"${record.id}"` : ''}</span>;
};

export const InventoryTicketList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"issueId"} />
        <FieldGuesser source={"token"} />
        <FieldGuesser source={"countAttemptsMax"} />
        <FieldGuesser source={"countAttemptsCurrent"} />
        <FieldGuesser source={"reports"} />
    </ListGuesser>
);

export const InventoryTicketShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"issueId"} addLabel={true} />
        <FieldGuesser source={"token"} addLabel={true} />
        <FieldGuesser source={"countAttemptsMax"} addLabel={true} />
        <FieldGuesser source={"countAttemptsCurrent"} addLabel={true} />
        <FieldGuesser source={"reports"} addLabel={true} />
    </ShowGuesser>
);