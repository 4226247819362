import {
    ListGuesser,
    ShowGuesser,
    FieldGuesser
  } from "@api-platform/admin";
import { JsonField, JsonInput } from "react-admin-json-view";
import { List, Datagrid, TextField, EmailField, TopToolbar, ListButton, ShowButton, EditButton, Edit } from 'react-admin';

const ReportTitle = ({ record }) => {
    return <span>Report {record ? `"${record.id}"` : ''}</span>;
};

const InventoryReportShowActions = ({ basePath, record, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <EditButton basePath={basePath} record={record} />
    </TopToolbar>
);

export const InventoryReportList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"submitedIp"} />
        <FieldGuesser source={"ticket"} />
        <FieldGuesser source={"validJson"} />
    </ListGuesser>
);

export const InventoryReportListReact = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="submitedIp" />
            <TextField source="ticket" />
            <TextField source="validJson" />
        </Datagrid>
    </List>
);
  
export const InventoryReportShow = props => (
    <ShowGuesser title={<ReportTitle />} action={<InventoryReportShowActions />} {...props}>
        <FieldGuesser source={"submitedIp"} addLabel={true} />
        <FieldGuesser source={"ticket"} addLabel={true} />
        <JsonField source={"json"} addLabel={true} reactJsonOptions={{
          collapsed: false, 
        }}/>
        <FieldGuesser source={"validJson"} addLabel={true} />
    </ShowGuesser>
);